table  td {
    width: auto!important;

}

#calendar table tr {
    box-shadow: none;
    border-radius: 0;
}
#calendar  table th:last-child {
    border-radius: 0!important;
}
.fc-event-main {
    padding:3px!important;
}
.fc-direction-ltr  button {
    font-size: 15px!important;
padding: 5px!important;
}
.fc-toolbar-title {
    font-size: 1.35em!important;
}
@media screen and (max-width: 900px) {
    .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
        display: block!important;
        text-align: center!important;

    }
    .fc-toolbar-title {
       margin: 10px 0!important;
    }
    .fc-direction-ltr  button {
        font-size: 13px!important;
        padding: 5px!important;
    }
    .fc-toolbar-title {
        font-size: 1.2em!important;
    }

}