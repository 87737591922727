header {
    background-color: var(--color-primary);
    z-index: 100;
}

.userSubmenu {
    right:-13px!important;
    margin: 0;
    bottom: -97px!important;
    padding: 5px 0 0 10px;
    text-align: left!important;
    width: 50%;
    box-shadow: 0px 5px 8px rgba(0,0,0,0.2);
    border-radius: 5px;
    z-index: 9999!important;
    transition: visibility 2s;
}

.userSubmenu ul {
    list-style: none;
    padding: 0 10px;
    margin-bottom:5px;
}
.iconSubmenuHolder {
    cursor: pointer;
}

/*
.logoContainer, .borderedBox {
    border-right: 3px solid #303642;
}*/


@media  screen and (min-width: 768px) {

    header {
        display: none!important;
    }

}
