@media (max-width: 768px) {
    /*    .tableCommunication {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
            gap: 1rem;
        }*/
    .members-table thead {
        display: none;
    }

    .members-table tr {
        min-width: 100%;
        display: inline-grid;
        grid-template-columns: auto auto;
        margin: 20px 0;
    }

    .members-table .azioni {
        grid-column-start: 1;
        grid-column-end: 3;
    }

    /*.members-table td {
        display: block;
        width: 100%;
        text-align: left;
        position: relative;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        box-sizing: border-box;
    }*/
    .members-table td::before {
        content: attr(data-label);
        display: block;
        font-weight: bold;
        text-align: left;
        margin-bottom: 0.5rem;
        color: #6c757d;
    }

}

.members-table.compact {
    border-collapse: collapse;
    border-spacing: 0 !important;

}

.members-table.compact tr, .members-table.compact td {
    font-size: 13px !important;
    border-bottom: 1px solid #dedede;
}

.members-table.compact img {
    width: 24px !important;
}
.members-table.compact button{
  font-size: 10px!important;
}
.members-table.compact .azioni svg{
  font-size: 18px!important;
}
.members-table.compact thead tr th{
    font-size: 10px!important;
}
.table-responsive.members-table.compact  td:last-child, .table-responsive.members-table.compact  td:first-child {
    border-radius: 0!important;
    border-bottom: 1px solid #dedede;

}
.table-responsive.members-table.compact {
    margin-top: 15px!important;
}
