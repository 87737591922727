.datePickerWrapper {
    width: 100%;
}

.datePickerContainer {
    width: 100%;
}

.date-picker-input {
    position: relative;
    width: 100%;
}

.date-picker-input input {
    width: calc(100% - 30px); /* Adjust as needed */
    padding-right: 30px; /* Space for the icon */
}

.date-picker-input .calendar-icon {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    cursor: pointer;
}

.react-datepicker-popper {
    z-index: 4 !important;
}