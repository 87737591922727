.sidebar {
    /*    display: flex;
        flex-direction: column;*/
    position: relative;
    max-height: 100%;
    width: 250px;
    min-width: 250px;
    padding-left: 10px;
  /*  margin-top:40px!important;
    bottom: 40px!important;*/
    /*margin-bottom: 40px!important;*/
    border-radius: 10px;
    background-color: var(--color-primary) !important;
    padding-top: 50px !important;
    z-index: 1;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;  /* For Internet Explorer and Edge */
}

.sidebar::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}

.sidebar ul {
    /*    display: flex;
        flex-direction: column;*/
    margin-top: 30px;
}
.sidebar svg {
    background-color: transparent!important;
}
.openSidebar.hide {
    position: absolute !important;
    right: -41px !important;
    top: 11% !important;
    width: 40px;
    height: 40px;
    display: inline-flex;
    border-radius: 20%;
}
.openSidebar.show {
    border-radius: 20%;
    width: 40px;
    height: 40px;
    display: inline-flex;
    position: absolute;
    right: -2px !important;
    top: 11% !important;
}

.sidebar .active {
    background-color: #fafafa;
    border-radius: 10px 0 0 10px;

}
li:not(.page-item).active {
    background-color: #fafafa;
    border-radius: 10px 0 0 10px;
    padding: 10px;
    margin-bottom: 10px;

}
.sidebar .active a {
    color:var(--color-primary) !important;
    font-weight: bold;
}
.active span:not(.error_field) {
    /*background-color:var(--color-primary)!important;*/
    color: var(--secondary)
}


.hiddenSide {
    position: absolute;
   margin-bottom: 40px;
    height: 100%;
    left: -250px !important;


}

.navigation-menu {
    list-style: none;
    padding: 0;
}

ul {
    width: 100% !important;
}

.sidebar li {
    padding-left: 28px;
    padding-bottom: 10px;
}


.sidebar {
    font-size: 13px !important;
}

.sidebar p {
    font-weight: 700
}

/*
.sidebar li:not(.subLi), .sidebar p.border-light {
    border-bottom: 2px solid #414C62 !important;
}*/

@media  screen  and (max-width: 799px){
    .sidebar {

        position: absolute;
        height: 100%;
        z-index: 10569!important;
    }
    .sidebar li  a{
        font-size: 12px!important;
    }
    .sidebar li {
        padding-left: 18px;
        padding-bottom: 5px;
    }

}