:root {
    --color-primary: #0A2A3B !important;
    --secondary: #48ADE2 !important;
    --orange: #E95526 !important;
    --gradient: linear-gradient(35deg, rgba(10, 42, 59, 1) 0%, rgba(21, 65, 88, 1) 49%, rgba(33, 90, 120, 1) 67%, rgba(72, 173, 226, 1) 94%);
    --gradientReversed: linear-gradient(-35deg, rgba(72, 173, 226, 1) 0%, rgba(33, 90, 120, 1) 29%, rgba(21, 65, 88, 1) 47%, rgba(10, 42, 59, 1) 94%);
    --grey: #8090a8
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Montserrat', sans-serif !important;
}

h2, .h2, .h1 {
    font-family: 'Oswald', sans-serif !important;
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root, body, html {
    /*margin-top: -22px;*/
    height: 100% !important;
    min-height: 100% !important;
}

#root {
    margin-top: -24px;
}

.App {
    /* display: flex;
     align-items: stretch;*/
    /*height: 100% !important;*/
    width: 100%;
    /*background-color: var(--color-primary);*/
    background: var(--gradientReversed);
    height: 100% !important;

    /*overflow: auto;*/

}

.layoutContainer .container-fluid {
    overflow-x: auto;

}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    margin-top: 10px;
    margin-bottom: 10px;

    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: var(--secondary);
    /*-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);*/
}

::-webkit-scrollbar-thumb:window-inactive {
    background: var(--secondary);
}

.cursor-pointer {
    cursor: pointer;
}

.scroll-box {
    overflow-y: scroll;
    height: 200px;
    border: 1px solid #ccc;
}

.bg-grey {
    background: var(--grey) !important;
}

.text-grey {
    color: var(--grey) !important;
}

.mainContent {
    /*  width: calc(100% - 250px)!important;
      margin-left: 250px!important;*/
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #fafafa;
    border-radius: 40px 0 0 40px;
    /*margin-left: 10px !important;*/
    /*margin-right: 20px !important;*/
}

h5 {
    font-family: 'Oswald', sans-serif;
    font-weight: 400 !important;
    font-size: 25px !important;
}

h5.fw-bold {
    font-weight: 400 !important;
}

.bgGradient {
    background: linear-gradient(35deg, rgba(10, 42, 59, 1) 0%, rgba(21, 65, 88, 1) 49%, rgba(33, 90, 120, 1) 67%, rgba(72, 173, 226, 1) 94%);
}

.bgGradientReversed {
    background: linear-gradient(-35deg, rgba(72, 173, 226, 1) 0%, rgba(33, 90, 120, 1) 29%, rgba(21, 65, 88, 1) 47%, rgba(10, 42, 59, 1) 94%);
}

.sidebar.hiddenSide + div.mainContent {
    width: 100% !important;
    margin-left: 20px !important;
    /*margin-right: 20px;*/
    /*margin-left: 0px !important;*/

}

.layoutContainer {
    /*margin-top: 50px!important;*/
    align-items: stretch !important;
    /*background-color: #fafafa;*/
    /*min-height: 100%;*/
    height: calc(100vh - 55px);


}

.iconSubmenuHolder {
    cursor: pointer;

}

.bg-orange {
    background-color: var(--orange);
}

.bkg-primary {
    background-color: var(--color-primary) !important;
}

.bkg-second {
    background-color: var(--secondary) !important;
}

.textPrimary {
    color: var(--color-primary) !important;
}

.text-second {
    color: var(--secondary) !important;
}

a {
    color: var(--color-primary) !important;
}

input[readonly], input[readonly]:focus, form[readonly=readonly] input {
    background-color: #e9ecef;
}

.activeIcon svg {
    color: var(--color-primary)
}

table td {
    max-width: 330px;
    width: 330px;
    vertical-align: middle;
    font-size: 14px;
}


/*Gestione errori*/
form .row .col {
    position: relative;
}


.disabled {
    border: none;
    background-color: #e9ecef;
}

thead tr th {
    font-size: 13px;
    vertical-align: middle !important;
}

.text-smaller {
    font-size: 10px;
}


.error_field {
    position: absolute;
    text-transform: uppercase;
    font-size: 8px;
    right: 20px;
    background-color: var(--bs-danger);
    color: #fff;
    padding: 1px 4px;
    bottom: -8px !important;
    border-radius: 5px !important;
}

.error_empty_field {
    position: absolute;
    text-transform: uppercase;
    font-size: 8px;
    right: 20px;
    background-color: var(--bs-danger);
    color: #fff;
    padding: 1px 4px;
    bottom: 0 !important;
    border-radius: 5px !important;
}

/*Posizionamento diverso per gli errori nel caso in cui la label sia sopra al campo*/
.position-relative.errorField_up .error_field {
    position: relative;
    display: inline-block;
    right: 0;
}

.trainings_weekly + div .position-relative.errorField_up .error_field {
    position: relative;
    display: block;
    right: 0;
    margin-top: 5px;
    cursor: pointer;
}

.error_empty_field + input, .error_field + input, .error_empty_field + select, .error_field + select, .error_row, .error_field + input + .rmsc > .dropdown-container, .error_field + input + div > .quill {
    border: 1px solid var(--bs-danger) !important;
}

.spinnerPosition {
    top: 0%;
    left: 0%;
    margin: auto;
    width: 50px;

}

.spinnerPosition svg {
    z-index: 999;
}

.spinnerPosition:before {
    width: 100%;
    height: 100vh;
    content: '';
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    z-index: 1;
}

/*Paginazione*/
.page-link {
    font-size: 14px !important;
    padding: 5px 10px !important;
}

/*Tabelle stili*/
table:not(.noRounded) th:first-child {
    border-radius: 10px 0 0 0;
}

table:not(.fc-col-header):not(.noRounded) th:last-child {
    border-radius: 0 10px 0 0;
}


.table-striped {
    border: 0px;
}


/*Esquerda*/
.table-striped > thead > tr > th:first-child {
    border-left-width: 0px;
}

/*Direita*/
.table-striped > thead > tr > th:last-child {
    border-right-width: 0px;
}

/*Topo*/
.table-striped > thead > tr:first-child > th, .table-striped > thead > tr:first-child {
    border-top-width: 0px;
}

.table-striped > tbody > tr:last-child > td {
    border-bottom-widht: 0px
}

a.nav-link.active {
    background-color: var(--secondary) !important;
    color: #fff !important;
}

.nav-tabs li button {
    color: var(--color-primary)
}

.nav-tabs li button.active {
    color: var(--secondary) !important;
    font-weight: bold;
}

.visible {
    display: block !important; /* Use !important to override other styles if necessary */
}

.page-item.active span {
    background-color: var(--secondary) !important;
    border-color: var(--secondary) !important;
}

#basic-nav-dropdown {
    display: flex;
    align-items: center;
}

a.dropdown-item:focus {
    background: var(--secondary);
    color: #fff;
}

/*datePicker fix*/
.react-datepicker-popper .react-datepicker {
    display: flex;
}

/*Backdrop offcanvas*/
.offcanvas-backdrop {
    z-index: 1 !important;
}

.table-responsive.noRounded {
    border-collapse: separate;
    border-spacing: 0 15px;
}

.table-responsive.noRounded td {
    border-bottom: 0;
}

.table-responsive.noRounded td:first-child {
    border-bottom: 0;
    border-radius: 15px 0 0 15px;
}

.table-responsive.noRounded td:last-child {
    border-bottom: 0;
    border-radius: 0 15px 15px 0;
}

.table-responsive:not(.noRounded) tbody td {
    background-color: rgba(var(--bs-light-rgb)) !important
}

.table-responsive:not(.noRounded) tbody tr {
    box-shadow: none !important;
}

.table-responsive td:first-child {
    border-bottom: 0;
    border-radius: 15px 0 0 15px;
}

.table-responsive td:last-child {
    border-bottom: 0;
    border-radius: 0 15px 15px 0;
}

@media  screen and (max-width: 767px) {

    .sidebar.hiddenSide + div.mainContent {
        width: 100% !important;
        margin-left: 0 !important;
        border-radius: 0;;
        margin-top: 45px


    }

}