.table:not(.noRounded) {
    border-collapse: separate;
    border-spacing: 0 15px;
}
table:not(.noRounded) tr {
    border-color: inherit;
    border-style: solid;
    /* background: #fff; */
    border-bottom: transparent!important;
    box-shadow: 1px 1px 5px 0 #dedede;
    border-radius: 14px;
}

table:not(.noRounded) tr td {
    background: transparent!important;
}

.teamCard .nav-item {
    border: 1px solid #dedede;
    border-radius: 5px;
    margin: 2px 0;
}