.quill {
    border-radius: 10px !important;
}

.ql-editor {
    min-height: 300px !important;

}

.ql-toolbar.ql-snow {
    border-radius: 10px 10px 0 0;
    border: 1px solid #dedede;
}

.ql-container.ql-snow {
    border-radius: 0 0 10px 10px !important;
    border: 1px solid #dedede;
}

.rmsc.disabled .dropdown-container{
    background-color: var(--bs-secondary-bg);
}
.rmsc .dropdown-container {
    text-align: start;
    color: #000!important;
    border: var(--bs-border-width) solid var(--bs-border-color)!important;
}
.dropdown-container .gray {
    color: #000!important;
}
.communicationsCard {
    max-height: 250px!important;
    height: 250px!important;
    justify-content: space-between;
}
.badge.bg-primary {
    background-color: var(--color-primary)!important;
}