@media (max-width: 768px) {
    .table-responsive.teams {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        gap: 1rem;
    }
    .table-responsive.teamMembers tbody tr {
        display: inline-grid;
        grid-template-columns: auto auto ;
    }
    .table-responsive.teamMembers tbody tr td:nth-child(2)  {
        grid-row-start: 3;
        grid-row-end: 4;
    }
    .table-responsive.teams thead, .table-responsive.teamMembers thead {
        display: none;
    }
    .table-responsive.teamMembers .teamMemberName {
        grid-column-start: 1;
        grid-column-end: 3;
    }
    .table-responsive.teams tr, .table-responsive.teamMembers tr{
       min-width: 100%;
        display: block;
        margin: 20px 0;
        box-shadow: 1px 6px 8px #dedede;
    }

    .table-responsive.teams td, .table-responsive.teamMembers td {
        display: block;
        width: 100%;
        text-align: left;
        position: relative;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 5px;
        padding-bottom: 10px;
        box-sizing: border-box;
    }

    .table-responsive.teamMembers td::before,   .table-responsive.teams td::before {
        content: attr(data-label);
        display: block;
        font-weight: bold;
        text-align: left;
        color: #6c757d;
    }




}