.bgLogin {
    background: linear-gradient(35deg, rgba(10,42,59,1) 0%, rgba(21,65,88,1) 49%, rgba(33,90,120,1) 67%, rgba(72,173,226,1) 94%);

    /*box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);*/
    height:100vh
}

.loginContainer {
    border-radius: 50px;
    border: 5px solid #fff;
    /*border: 4px solid #FFF;*/
    background: var(--color-primary);
    box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.75)
}

.loginContainer button {
    border-radius: 90px;
    border:none;
    background: var(--secondary);
    color:#fff
}

.loginContainer a {
    color:#fff;
}

::placeholder {
    color:#fff!important;
}

-webkit-input-placeholder::after {
    content: 'test'; display: block; width: 20px; height: 20px;
}

input:focus {
    box-shadow: 0 0 0 0!important;
}

.bgLogin input {
    color:#fff!important;
}

input::-ms-reveal {
    display: none;
}

