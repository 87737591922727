@media (max-width: 768px) {
/*    .tableCommunication {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        gap: 1rem;
    }*/

    .tableCommunication thead {
        display: none;
    }

    .tableCommunication tr{
        min-width: 100%;
        display: inline-grid;
        grid-template-columns: auto auto;
        margin: 20px 0;
    }


    /*.tableCommunication td {
        display: block;
        width: 100%;
        text-align: left;
        position: relative;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        box-sizing: border-box;
    }*/

    .tableCommunication td::before {
        content: attr(data-label);
        display: block;
        font-weight: bold;
        text-align: left;
        margin-bottom: 0.5rem;
        color: #6c757d;
    }




}